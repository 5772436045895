import api from '@/services/api';

const url = 'drugstore-partner';

const findAll = () => api.get(`${url}/read-drugstore-partner`);

const create = (partner) => api.post(`${url}/create-drugstore-partner`, partner);

const update = (partner) => api.put(`${url}/update-drugstore-partner`, partner);

const deleteOne = (id) => api.delete(`${url}/delete-drugstore-partner/${id}`);

export default {
  findAll, create, update, deleteOne,
};
