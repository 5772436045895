<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="partner"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Farmácias Parceiras</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.discount`]="{ item }">
        <span>{{ item.discount }}%</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              @click="prepareToDelete(item)"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      <template #[`item.active`]="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"

          disabled
        />
      </template>
      <template #[`item.createdAt`]="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <delete-confirmation
      :visible="dialogDeleteConfirmation"
      @cancel="dialogDeleteConfirmation = false"
      @confirm="deleteItem()"
    />
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-drugstore-partner
        v-if="dialog"
        :populate-with="partnerToEdit"
        @partner-added="addPartner"
        @partner-edited="editPartner"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import DrugstorePartnerService from '../../../services/drugstore-partner.service';
import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'DrugstorePartners',
  components: {
    'form-drugstore-partner': () => import('./Form'),
    DeleteConfirmation,
  },
  data: () => ({
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Nome',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Telefone',
        sortable: false,
        value: 'phone',
      },
      {
        text: 'Social',
        sortable: false,
        value: 'socialMedia',
      },
      {
        text: '% de Desconto',
        sortable: false,
        value: 'discount',
      },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    partner: [],
    search: '',
    dialog: false,
    partnerToEdit: {},
    partnerToDelete: {},
    dialogDeleteConfirmation: false,
    dialogDelete: true,
    deleteItemID: null,
  }),
  async created() {
    await this.findAllDrugstorePartners();
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.partnerToEdit = {};
    },

    editItem(partner) {
      this.partnerToEdit = partner;
      this.dialog = true;
    },

    editPartner(partner) {
      const index = this.partner.findIndex(
        (listPartner) => listPartner.id === partner.id,
      );
      this.partner[index] = partner;
    },

    prepareToDelete(item) {
      this.dialogDeleteConfirmation = true;
      this.deleteItemID = item.id;
    },

    async deleteItem() {
      try {
        this.dialogDeleteConfirmation = false;
        await DrugstorePartnerService.deleteOne(this.deleteItemID);
        this.$toast.success('Parceiro excluído com sucesso');
        const index = this.partner.findIndex(
          (listPartner) => listPartner.id === this.deleteItemID,
        );
        this.partner.splice(index, 1);
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    async addPartner() {
      await this.findAllDrugstorePartners();
    },

    // async editPartner() {
    //   await this.findAllDrugstorePartners();
    // },

    async findAllDrugstorePartners() {
      this.partner = await DrugstorePartnerService.findAll();
    },
  },
};
</script>
